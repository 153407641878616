import * as React from 'react';
import { useLayoutEffect } from 'react';

type ThemeModes = 'light' | 'dark';
type UIContextProps = {
  navIsVisible: boolean;
  setNavIsVisible: React.Dispatch<boolean>;
  mode: ThemeModes;
  setMode: React.Dispatch<ThemeModes>;
  setNoScroll: React.Dispatch<boolean>;
  noScroll: boolean;
  isDark: boolean;
};

const UIContext = React.createContext<UIContextProps>({
  navIsVisible: false,
  setNavIsVisible: () => undefined,
  mode: 'light',
  setMode: () => undefined,
  setNoScroll: () => undefined,
  noScroll: false,
  isDark: false,
});

type UIContextProviderProps = { children: React.ReactNode };

const UIContextProvider = ({
  children,
}: UIContextProviderProps): JSX.Element => {
  const [navIsVisible, setNavIsVisible] = React.useState(true);
  const [mode, setMode] = React.useState<ThemeModes>('light');
  const [noScroll, setNoScroll] = React.useState(false);

  useLayoutEffect(() => {
    const html = document.querySelector('html');

    if (mode === 'light') {
      html?.classList.remove('dark');
    } else {
      html?.classList.add('dark');
    }
  }, [mode]);

  useLayoutEffect(() => {
    const body = document.querySelector('body');

    if (noScroll) {
      body?.classList.add('no-scroll');
    } else {
      body?.classList.remove('no-scroll');
    }
  }, [noScroll]);

  return (
    <UIContext.Provider
      value={{
        navIsVisible,
        setNavIsVisible,
        setMode,
        mode,
        isDark: mode === 'dark',
        setNoScroll,
        noScroll,
      }}>
      {children}
    </UIContext.Provider>
  );
};

const useUIContext = (): UIContextProps => React.useContext(UIContext);

const tMode =
  (ifDark: string | number, ifLight = '') =>
  (props: { isDark?: boolean }): typeof ifDark | typeof ifLight =>
    props.isDark ? ifDark : ifLight;

export { UIContextProvider, useUIContext, tMode };
