import { memo, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import Spinner from '@ja/components/common/Spinner/Spinner';
import { bemify } from '@ja/helpers/bemClassHelper';

/**
 * Main website pages
 */
const Home = lazy(() => import('@ja/pages/Home'));
const AppraisalServices = lazy(() => import('@ja/pages/AppraisalServices'));
const AboutUs = lazy(() => import('@ja/pages/AboutUs'));
const Contact = lazy(() => import('@ja/pages/Contact'));
const AskForCash = lazy(() => import('@ja/pages/AskForCash/AskForCash'), {});
const AskForCashConnectedCanonical = lazy(() =>
  import('@ja/pages/AskForCash/AskForCashConnectedCanonical')
);
const PageNotFound = lazy(() => import('@ja/components/PageNotFound'));

const appClasses = bemify('ja-content');

const Routes = memo(({ location }) => (
  <div className={appClasses()}>
    <Suspense fallback={<Spinner />}>
      <Switch location={location}>
        <Route
          exact
          path="/appraisal-services/:subpage?/"
          render={() => <AppraisalServices location={location} />}
        />
        <Route
          exact
          path="/about-us/:subpage?/"
          render={() => <AboutUs location={location} />}
        />
        <Route
          exact
          path="/contact/"
          render={() => <Contact location={location} />}
        />
        <Route
          exact
          path="/ask-for-cash/"
          render={() => <AskForCash location={location} />}
        />
        <Route
          exact
          path="/AskForCash/"
          render={() => <AskForCashConnectedCanonical location={location} />}
        />
        <Route exact path="/" render={() => <Home />} />
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Suspense>
  </div>
));

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Routes;
