import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { closeModal } from '@ja/redux/actions/modal';
import { getContentComponent } from './ContentComponents/ContentTypes';
import { getModalClasses } from './ModalClassNames';
import { openContact } from '@ja/redux/actions/contact';
import { disableBodyScrolling } from '@ja/helpers/preventScrolling';

import './index.css';

import { bemify } from '@ja/helpers/bemClassHelper';
import JAIcon from '../JAIcon';

Modal.setAppElement('#root');

export const ModalComponent = ({
  classNames: { bemClasses, overlayClassName, className },
  closeModal,
  ContentComponent,
  handleCTA,
  isOpen,
  modalContent,
}) => {
  const handleCloseModal = (e) => {
    e.preventDefault();
    closeModal();
  };
  const modalClasses = bemClasses
    ? bemify(['modal', bemClasses()])
    : bemify('modal');

  const CloseButton = (props) => (
    <button
      className={modalClasses('close-modal')}
      onClick={handleCloseModal}
      {...props}>
      <JAIcon name="close" cssClasses={modalClasses('close-modal-icon')} />
    </button>
  );

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={overlayClassName}
      className={className}
      onRequestClose={handleCloseModal}>
      {ContentComponent && (
        <ContentComponent
          content={modalContent}
          bemClasses={bemClasses}
          handleCloseModal={handleCloseModal}
          CloseButton={(props) => <CloseButton {...props} />}
          handleCTA={handleCTA}
        />
      )}
    </Modal>
  );
};

ModalComponent.defaultProps = {
  classNames: {
    bemClasses: undefined,
    overlayClassName: '',
    className: '',
  },
  isOpen: false,
  ContentType: false,
};

ModalComponent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  ContentType: PropTypes.node,
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state.modal,
  ContentComponent: getContentComponent(state.modal.modalType),
  classNames: getModalClasses(state.modal.modalType),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  handleCTA: (payload) => {
    disableBodyScrolling();
    dispatch(
      openContact({
        contactType: 'GetInTouch',
        ...payload,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
