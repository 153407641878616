import { CONTACT_CLOSED, CONTACT_OPENED } from '@ja/redux/actions/contact';

const initialState = {
  isOpen: false,
  contactType: '',
  jewelry: '',
  purpose: '',
};

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case CONTACT_OPENED:
      return {
        ...state,
        isOpen: true,
        ...payload,
      };
    case CONTACT_CLOSED:
      return initialState;
    default:
      return state;
  }
}
