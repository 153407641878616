import { getDavidsYearsOfExperience } from '../AboutUs/SubPages/WhatWeveDone/TimeLineHelper';

import antique_jewelry from './assets/antique-jewelry.svg';
import diamond from './assets/diamond.svg';
import colored_stone from './assets/colored-stone.svg';
import watch from './assets/watch.svg';
import estate from './assets/estate.svg';
import jewelry_accessories from './assets/jewelry-accessories.svg';

import insurance from './assets/insurance.svg';
import liquidation from './assets/liquidation.svg';
import verification from './assets/verification.svg';
import identifySort from './assets/identify-sort.svg';
import litigationSupport from './assets/litigation-support.svg';
import estateTax from './assets/estate-tax.svg';
import postLoss from './assets/post-loss.svg';
import expertWitness from './assets/expert-witness.svg';

export const APPRAISAL_CONSTANTS = {
  PURPOSES: [
    {
      value: 'Insurance Purposes',
      title: 'Insurance',
      icon: insurance,
      name: 'insurance',
      info: {
        language: [
          {
            title: 'Why an insurance appraisal?',
            description: [
              'Your insurance premiums are based on our estimated values.',
              'Our values reflect competitive & realistic replacement costs so your insurance premiums are not inflated,',
              'and still provide easy settlement in the unlikely event of a loss.',
            ],
          },
        ],
      },
    },
    {
      value: 'Liquidation Purposes',
      title: 'Liquidation',
      icon: liquidation,
      name: 'liquidation',
      info: {
        language: [
          {
            title: 'Thinking of liquidating?',
            description: [
              'Before you sell, we make sure you know what you have and what it’s worth so you can recognize a fair offer and feel confident negotiating.',
              'We also provide interactive spreadsheets with high cash values for you to use as a guide, that day or any time in the future.',
            ],
          },
        ],
      },
    },
    {
      value: 'Verification',
      title: 'Verification',
      icon: verification,
      name: 'verification',
      info: {
        language: [
          {
            title: 'Want to verify you have, what you have?',
            description: [
              'As we walk you through the process of verifying quality and value - whatever your circumstance: inheritance, recently purchased – we give you all the angles.',
              'We want you to know more than enough to feel confident in whatever decision lies ahead of you.',
            ],
          },
        ],
      },
    },
    {
      value: 'Identificaton and Sorting',
      title: 'Identify & Sort',
      icon: identifySort,
      name: 'identify and sort',
      info: {
        language: [
          {
            title: 'Have a lot of jewelry?',
            description: [
              'Accurately identify and sort your valuables – we verify quality and value so you can leave knowing as much as possible, feeling organized & confident in whatever decision lies ahead.',
              'We provide interactive spreadsheets that list high cash values and sort the details of the evaluation for you to use as a guide, that day or any time in the future.',
            ],
          },
        ],
      },
    },
    {
      value: 'Litigation Support',
      title: 'Litigation Support',
      icon: litigationSupport,
      name: 'litigation support',
      info: {
        language: [
          {
            title: 'Need resolution support in legal context?',
            description: [
              'We offer conflict resolution to protect interests through an evaluation that is fair and accurate with support documentation.',
              'We strategize and compose questions for deposition, interrogatory and discovery related to insurance fraud, counterfeiting and price-fixing.',
            ],
          },
        ],
      },
    },
    {
      value: 'Estate Tax',
      title: 'Estate Tax',
      icon: estateTax,
      name: 'estate tax',
      info: {
        language: [
          {
            title: 'Need an appraisal report for the IRS?',
            description: [
              'We establish the fair market value for each item and perform additional market research for significant jewelry items.',
              'These appraisals will list the documented sales prices of comparable items (e.g. researched sales at auction). When appropriate, prices are supported by analysis of similarities and differences between the subject and the comparable item(s).',
              'These details help the reader (e.g. IRS agent) to understand the fair market value conclusion in the report.',
            ],
          },
        ],
      },
    },
    {
      value: 'Post Loss',
      title: 'Post Loss',
      icon: postLoss,
      name: 'post loss',
      info: {
        language: [
          {
            title: 'No longer in posession of an item?',
            description: [
              `With our ${getDavidsYearsOfExperience()} years of experience, we assist in assigning the value of an undocumented lost or stolen item.`,
              'Our hypothetical valuation is based on information supplied by the client and supported by photographs and a description of the item.',
            ],
          },
        ],
      },
    },
    {
      value: 'Expert Witness',
      title: 'Expert Witness',
      icon: expertWitness,
      name: 'expert witness',
      info: {
        language: [
          {
            title: 'In dispute over a jewelry subject?',
            description: [
              'We perform quantitative and qualitative analysis of the item in question.',
              'We then provide the Court an impartial opinion on certain matters within our expertise which are in dispute.',
            ],
          },
        ],
      },
    },
  ],
  JEWELRY: [
    {
      value: 'Diamond Appraisals',
      title: 'Diamond',
      name: 'diamond',
      hint: 'Not sure, but I think I have a diamond...',
      icon: diamond,
      info: {
        language: [
          {
            title: 'Loose diamond appraisal:',
            description: [
              'We identify, grade, measure, and weigh the stone.',
              'We scan your stone on our advanced proportion scanner for light performance analysis.',
              'Having your stone loose enables us to give you the most accurate carat weight & opinion of color, cut and clarity.',
            ],
          },
          {
            title: 'In-setting diamond appraisal:',
            description: [
              'We can identify, grade and measure the stone,',
              'But the weight and certain determinations will be based on measurements and calculated assumptions.',
              'We dig into how the stone is cut and how that affects the light performance.',
            ],
          },
        ],
        report: [
          'Explanation of the definition of value.',
          'Instructions for the appropriate use of the appraisal document.',
          'Details of weight, shape, cut, color, clarity.',
          'Internal characteristics of the diamond are diagramed',
          'Cut/proportion analysis report.',
          'Light performance report (loose diamonds).',
          'Enlarged digital color photos of the diamond (ring).',
          'Photomicrographs if needed for insurance claims.',
          'The grading systems used.',
          'CV & David Wolf MGA\xAE GG, signature.',
        ],
      },
    },
    {
      value: 'Colored Stone Appraisals',
      title: 'Colored Stone',
      name: 'colored stone',
      hint: 'I think I have a fine gemstone - maybe sapphire?',
      icon: colored_stone,
      info: {
        language: [
          {
            title: 'Loose colored stone appraisal:',
            description: [
              'We identify, grade, measure, and weigh the stone.',
              'We dig into how the stone is cut and how that affects the light performance.',
              'This enables us to give you the most accurate carat weight, opinion of color and clarity.',
            ],
          },
          {
            title: 'In-setting colored stone appraisal:',
            description: [
              'We can identify, grade and measure the stone,',
              'But the weight and certain determinations will be based on measurements and calculated assumptions.',
              'We dig into how the stone is cut and how that affects the light performance.',
            ],
          },
        ],
        report: [
          'Explanation of the definition of value.',
          'Instructions for the appropriate use of the appraisal document.',
          'Details of weight, shape, color, clarity.',
          'Internal characteristics of the stone are diagramed',
          'Cut/proportion analysis report.',
          'Enlarged digital color photos of the stone (ring).',
          'Photomicrographs if needed for insurance claims.',
          'The grading systems used.',
          'CV & David Wolf MGA\xAE GG, signature.',
        ],
      },
    },
    {
      value: 'Watch Appraisals',
      title: 'Watch',
      name: 'watch',
      hint: 'I think I have a unique watch, and want confirmation..',
      icon: watch,
      info: {
        language: [
          {
            title: 'With documents:',
            description: [
              'We can most easily verify the make, model, movement, serial number and other relevant (e)valuation details.',
              'We identify condition, unique & typical characteristics of your watch.',
              'We test for metal content or identify other exhibited material.',
            ],
          },
          {
            title: 'Without documents:',
            description: [
              'We search for signs/markings and characteristics that help identify: make, model, movement, serial number, etc.',
              'We identify condition, unique & typical characteristics of your watch.',
              'We test for metal content or identify other exhibited material.',
            ],
          },
        ],
        report: [
          'Explanation of the definition of value.',
          'Instructions for the appropriate use of the appraisal document.',
          'Details of brand, model, movement, & metal.',
          'Enlarged digital color photos of the watch and movement.',
          'The grading systems used.',
          'CV & David Wolf MGA\xAE GG, signature.',
        ],
      },
    },
    {
      value: 'Antique Jewelry Appraisals',
      title: 'Antique Jewelry',
      name: 'antique jewelry',
      hint: "I'm unsure, but I think I have a unique antique jewelry piece..",
      icon: antique_jewelry,
      info: {
        language: [
          {
            title: 'Many pieces can be categorized, Antique:',
            description: [
              'Diamond Rings',
              'Necklaces',
              'Earrings',
              'Bracelets',
              'Pearls, etc.',
            ],
          },
          {
            title:
              'Each kind of piece requires special attention to different details. These are a few points of interest:',
            description: [
              'Condition of the item.',
              'Unique vintage characteristics.',
              'Diamonds are graded: Shape, cut, color, clarity, weight.',
              'Pearls are identified, graded and measured.',
              'Metal content is determined by way of chemical testing and markings.',
            ],
          },
        ],
        report: [
          'Explanation of the definition of value.',
          'Instructions for the appropriate use of the appraisal document.',
          'Details of piece: identity, grade, condition, metal, brand, model, metal.',
          'Enlarged digital color photos of the pieces.',
          'The grading systems used.',
          'CV & David Wolf MGA\xAE GG, signature.',
        ],
      },
    },
    {
      value: 'Estate Appraisals',
      title: 'Estate',
      name: 'estate',
      hint: 'I need to assign values to some unique estate jewelry that I have..',
      icon: estate,
      info: {
        special: true,
        language: [
          {
            title: 'Estate tax report for IRS requirements:',
            description: [
              'We establish the fair market value for each item and perform additional market research for significant jewelry items.',
              'These appraisals will list the documented sales prices of comparable items (e.g. researched sales at auction). When appropriate, prices are supported by analysis of similarities and differences between the subject and the comparable item(s).',
              'These details help the reader (e.g. IRS agent) to understand the fair market value conclusion in the report.',
            ],
          },
          {
            title: 'Identify, sort and value documentation:',
            description: [
              'Get a report documenting every item along with market research.',
              'This is sometimes useful to help with the equitable distribution and often require less detailed market research and take less time.',
            ],
          },
          {
            title: 'Consultation option:',
            description: [
              "Sometimes you don't need a printed report and simply need information to help with the equitable distribution of the estate assets.",
              'We help identify, sort and approximate value to help you gain clarity & confidence in whatever decision lies ahead.',
            ],
          },
        ],
      },
    },
    {
      value: 'Designer Jewelry Appraisals',
      title: 'Designer Jewelry',
      name: 'designer jewelry',
      hint: 'I think I have a genuine designer piece by Cartier..',
      icon: jewelry_accessories,
      info: {
        language: [
          {
            title: 'We help identify authentic designer jewelry:',
            description: [
              "But the only one who can truly authenticate a signed piece, is the designer themself. e.g. If you think you have an authentic VCA piece but don't have documentation, we may suggest approaching VCA to confirm authenticity.",
              'If you need more clarity on this or want an explicit suggestion to help you in your special circumstance, {give us a call}',
            ],
          },
          {
            title:
              'Already know you have a designer piece, and just need it appraised?',
            description: [
              'We identify unique (designer) characteristics.',
              'We identify condition.',
              'We test for metal content and other material when appropriate.',
              'We grade and measure significant gemstones and diamonds designed into the piece.',
            ],
          },
        ],
        report: [
          'Explanation of the definition of value.',
          'Instructions for the appropriate use of the appraisal document.',
          'Details of piece: brand, model, metal, identity, grade, condition.',
          'Supportive research analysis.',
          'Enlarged digital color photos of the pieces.',
          'The grading systems used.',
          'CV & David Wolf MGA\xAE GG, signature.',
        ],
      },
    },
  ],
};
