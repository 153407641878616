export const CURRENT_ITEM = 'CURRENT_ITEM';
export const CURRENT_TYPE = 'CURRENT_TYPE';

export const getCurrentAppraisalItem = (payload) => ({
  type: CURRENT_ITEM,
  payload,
});

export const getCurrentAppraisalType = (payload) => ({
  type: CURRENT_TYPE,
  payload,
});
