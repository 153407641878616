import { DRAWER_OPENED, DRAWER_CLOSED } from '@ja/redux/actions/drawer';

const initialState = {
  drawerType: null,
  drawerProps: {},
  type: undefined,
};

export default function (
  state = initialState,
  { drawerProps, drawerType, type }
) {
  switch (type) {
    case DRAWER_OPENED:
      return {
        drawerProps,
        drawerType,
        type,
      };
    case DRAWER_CLOSED:
      return initialState;
    default:
      return state;
  }
}
