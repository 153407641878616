const getCSSVar = (cssVar) =>
  window.getComputedStyle(document.body).getPropertyValue(cssVar).trim();

// This a nameless function because it should getCSSVars right before
// whatever component this is being used in, is rendered.
// This is to avoid getCSSVar running before DOM content is loaded.
const getThemeColors = () => ({
  primary: getCSSVar('--purple'),
  primary75: getCSSVar('--select-icon-bg'),
  primary50: getCSSVar('--select-icon-bg__hover'),
  primary25: getCSSVar('--select-icon-bg'),
  neutral0: getCSSVar('--purple-bg'),
  faint: getCSSVar('--faint'),
  danger: getCSSVar('--danger'),
  label: getCSSVar('--tag'),
  placeholder: getCSSVar('--purple_60'),
  vividPurple: getCSSVar('--vivid-purple'),
});

export default getThemeColors;
