import { prepareSendInBlueApi } from './sendInBlue';

const capitalize = (string) => {
  if (typeof string === 'string')
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return string;
};

const createHTMLContent = (formValues) => {
  const values = Object.values(formValues);
  const keys = Object.keys(formValues);

  let div = document.createElement('div');
  let message = document.createElement('p');
  let myNameIs = document.createElement('p');
  let myNameIsText = '';
  let messageText = '';
  let ul = document.createElement('ul');
  let ul2 = document.createElement('ul');
  let ul3 = document.createElement('ul');

  for (var i = 0; i < values.length; i++) {
    const currentKey = keys[i];
    let value = values[i];
    let li = document.createElement('li');

    // if value is empty, skip it
    if (value.length < 1) {
      continue;
    }

    // create section title
    let title = document.createElement('span');
    title.setAttribute('style', 'font-weight:bold;');
    title.innerHTML =
      capitalize(currentKey === 'mightHave' ? 'I think I have' : currentKey) +
      ': ';

    // distinguish between jewelry list and purpose list
    let subUL = currentKey === 'purpose' ? ul3 : ul2;
    if (Array.isArray(value)) {
      value.forEach((val) => {
        let li2 = document.createElement('li');
        li2.innerHTML = val.label;
        subUL.appendChild(li2);
      });
      subUL.prepend(title);
    } else {
      if (currentKey === 'name') {
        myNameIsText = `Message from ${capitalize(value)}: `;
        continue;
      }
      if (currentKey === 'message') {
        messageText = `${value}.`;
        continue;
      }

      li.innerHTML = value;
      li.prepend(title);
      ul.appendChild(li);
    }
  }

  myNameIs.innerHTML = myNameIsText;
  message.innerHTML = messageText;
  div.append(myNameIs);
  div.append(message);
  div.append(ul);
  div.appendChild(ul2);
  div.appendChild(ul3);

  return div.innerHTML;
};

export const sendContactForm = (
  formValues,
  resetForm = () => {},
  setStatus = () => {},
  setSubmitting = () => {},
  contactType = ''
) => {
  const { sendSmtpEmail, apiInstance } = prepareSendInBlueApi();

  sendSmtpEmail.to = [{ email: 'lynn@justappraisers.com', name: 'Lynn Wolf' }];
  sendSmtpEmail.subject = `💎 Just Appraisers - ${contactType}`;
  sendSmtpEmail.sender = {
    name: 'Just Appraisers Website',
    email: 'lynn@justappraisers.com',
  };
  sendSmtpEmail.replyTo = {
    name: formValues.name,
    email: formValues.email,
  };
  sendSmtpEmail.htmlContent = createHTMLContent(formValues);

  apiInstance.sendTransacEmail(sendSmtpEmail).then(
    function (data) {
      resetForm();
      setStatus("Great! We received your message. We'll get in touch ASAP.");
      setSubmitting(false);
      setTimeout(() => {
        setStatus('');
      }, 8000);
      console.log('sent');
    },
    function (error) {
      setStatus("Uh-oh! Your message didn't send for some reason. Try again?");
      setSubmitting(false);
      console.error('send failed', error);
    }
  );
};
