import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Redirect } from 'react-router';

import closeIcon from '@ja/images/close.svg';
import logo from '@ja/images/logo-proto.svg';
import logoV2Dark from '@ja/assets/logov2/logo-mark-dark.svg';
import logoV2 from '@ja/assets/logov2/logo-mark.svg';

import { bemify } from '@ja/helpers/bemClassHelper';

const bemClasses = bemify('inline-svg');

const icons = {
  close: closeIcon,
  logo,
  logoV2Dark,
  logoV2,
};

const JAIcon = ({
  id,
  name,
  cssClasses,
  onMouseEnter,
  bubble,
  bubbleClass,
  text,
  textClass,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  const icon = icons[name];

  return (
    <span
      id={id}
      onClick={handleClick}
      className={bemClasses('parent')}
      onMouseEnter={onMouseEnter}>
      <SVG src={icon} className={cssClasses} />
      {text && <span className={bemClasses('text', textClass)}>{text}</span>}
      {bubble && <span className={bemClasses('background', bubbleClass)} />}
    </span>
  );
};

JAIcon.defaultProps = {
  name: '',
  customClass: 'custom-icon',
  classModifiers: '',
  disabled: false,
  text: '',
  bubble: false,
  onClick: () => {},
  onMouseEnter: () => {},
};

JAIcon.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  bubble: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
};

export default JAIcon;
