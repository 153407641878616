import React from 'react';
import { handleHowWeWorkOnClick } from '../modalHelpers';

import './JewelryModalContent.css';

export const JewelryModalContent = ({
  content,
  bemClasses,
  CloseButton,
  handleCloseModal,
  handleCTA,
}) => {
  const {
    info: { language, report },
    name,
    title,
    value,
  } = content;

  const renderLanguage = () =>
    language.map(({ description = '', title = '' }) => (
      <div key={title} className={bemClasses('language-content-container')}>
        <h3 className={bemClasses('language-title')}>{title}</h3>
        {description && (
          <ul className={bemClasses('language-description-list')}>
            {description.map((li) =>
              li.includes('{give us a call}') ? (
                <li key={li}>
                  {li.replace('{give us a call}', '')}
                  <a className={bemClasses('tel-call')} href="tel:2016024410">
                    give us a call
                  </a>
                  .
                </li>
              ) : (
                <li key={li}>{li}</li>
              )
            )}
          </ul>
        )}
      </div>
    ));

  const renderReportLanguage = () =>
    report && report.map((li) => <li key={li}>{li}</li>);

  return (
    <div className={bemClasses()}>
      <div className={bemClasses('header')}>
        <div className={bemClasses('icon')}>
          <img src={content.icon} alt={value} />
        </div>
        <h2 className={bemClasses('title')}>{value}</h2>
        <CloseButton />
      </div>
      <div className={bemClasses('body', report ? '' : 'row')}>
        <div className={bemClasses('language', report ? 'first-of' : '')}>
          {renderLanguage()}
        </div>
        {report && (
          <div className={bemClasses('report')}>
            <div className={bemClasses('report-content')}>
              <h3>The appraisal report will include:</h3>
              <ul>{renderReportLanguage()}</ul>
            </div>
          </div>
        )}
      </div>
      <div className={bemClasses('footer')}>
        <button
          onClick={() => handleCTA({ jewelry: title })}
          className={bemClasses('cta')}>
          Get your {name?.toLowerCase()} appraised
        </button>
        <button
          className={bemClasses('cta', 'info')}
          onClick={handleHowWeWorkOnClick(handleCloseModal)}>
          How we work
        </button>
      </div>
    </div>
  );
};
