import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeContact } from '@ja/redux/actions/contact';
import { enableBodyScrolling } from '@ja/helpers/preventScrolling';
import { InnerSlideout } from './InnerSlideoutTypes/InnerSlideoutTypes';
import JAIcon from '../common/JAIcon';
import { bemify } from '@ja/helpers/bemClassHelper';

import './index.css';
import { useOutsideClick } from '@ja/helpers/useClickOutside';

const contactSlideoutComponentClasses = bemify('contact-slideout');

const ContactSlideout = () => {
  const { isOpen, type } = useSelector(({ contact }) => ({
    isOpen: contact.isOpen,
    type: contact.contactType,
  }));

  const dispatch = useDispatch();
  const handleClose = () => {
    enableBodyScrolling();
    dispatch(closeContact());
  };

  const ref = useOutsideClick({ callback: handleClose });

  return (
    <div
      className={contactSlideoutComponentClasses(
        'wrapper',
        isOpen ? 'open' : 'close'
      )}>
      <div ref={ref} className={contactSlideoutComponentClasses('container')}>
        <div className={contactSlideoutComponentClasses('header')}>
          <h2 className={contactSlideoutComponentClasses('title')}>
            {type === 'GetInTouch' ? "Let's talk" : 'Free consultation'}
          </h2>
          <button
            className={contactSlideoutComponentClasses('close')}
            onClick={handleClose}>
            <JAIcon
              name="close"
              cssClasses={contactSlideoutComponentClasses('close-icon')}
            />
          </button>
        </div>

        <InnerSlideout type={type} />
      </div>
    </div>
  );
};

export default ContactSlideout;
