import { lazy, memo, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Modal from './components/common/Modal';
import Spinner from './components/common/Spinner/Spinner';
import ContactSlideout from './components/ContactSlideout';
import { JustAppraisersProviders } from './context';
import Routes from './routes/routes';

const Header = lazy(() => import('./components/Header'));

const App = memo(({ history: { location } }) => (
  <Suspense fallback={<Spinner />}>
    <JustAppraisersProviders>
      <span>
        <Header location={location} />
      </span>
      <Routes location={location} />
      <Modal />
      <ContactSlideout />
    </JustAppraisersProviders>
  </Suspense>
));

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps, null)(App));
