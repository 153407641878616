import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import getThemeColors from './SelectTheme';
import { bemify } from '@ja/helpers/bemClassHelper';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useMemo } from 'react';

const Select = ({
  isMulti = true,
  classNamePrefix = '',
  onSelect = () => {},
  options,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  customClass,
  customSelectStyles,
  isCreatable,
  note,
  ...otherProps
}) => {
  const [themeColors, setThemeColors] = useState({});

  useLayoutEffect(() => {
    setThemeColors(getThemeColors());
  }, []);

  const jaSelectClasses = bemify('ja-select', customClass);
  const customStyles = useMemo(
    () => ({
      control: (base, state) => ({
        ...base,
        color: themeColors.vividPurple,
        background: state.isFocused ? themeColors.faint : themeColors.neutral0,
        border: '1px solid transparent',
        borderColor: state.isFocused ? themeColors.neutral0 : 'transparent',
        cursor: 'text',
        '&:hover': {
          borderColor: 'transparent',
        },
        boxShadow: null,
      }),
      input: (base) => ({
        ...base,
        color: themeColors.vividPurple,
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: themeColors.placeholder,
        cursor: 'pointer',
        ':hover': {
          color: themeColors.vividPurple,
        },
      }),
      placeholder: (base, state) => ({
        ...base,
        color: themeColors.placeholder,
      }),
      menu: (base) => ({
        ...base,
        overflow: 'hidden',
        zIndex: '9999',
      }),
      option: (base) => ({ ...base, cursor: 'pointer' }),
      menuList: (base) => ({
        ...base,
        padding: 0,
        background: themeColors.faint,
      }),
      multiValueLabel: (base, state) => ({
        ...base,
        color: themeColors.vividPurple,
      }),
      multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: themeColors.danger,
          color: 'white',
        },
      }),
      multiValue: (base, state) => {
        return {
          ...base,
          // backgroundColor: state.isFocused ? themeColors.label : 'transparent',
          background: themeColors.label,
        };
      },
      ...customSelectStyles,
    }),
    [themeColors]
  );
  const handleOnFocus = () => {
    onFocus(name);
  };

  return (
    <CreatableSelect
      components={makeAnimated()}
      options={options}
      isMulti
      name={name}
      value={value}
      onFocus={handleOnFocus}
      onBlur={onBlur}
      onChange={onChange}
      onSelect={onSelect}
      className={jaSelectClasses()}
      classNamePrefix={classNamePrefix}
      theme={(base) => ({
        ...base,
        borderRadius: 12,
        colors: {
          ...base.colors,
          ...themeColors,
        },
      })}
      styles={customStyles}
      {...otherProps}
    />
  );
};

Select.propTypes = {
  classNamePrefix: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
};

export default Select;
