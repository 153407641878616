import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { FreeConsultationComponent } from './FreeConsultationComponent';
import { APPRAISAL_CONSTANTS } from '@ja/pages/AppraisalServices/appraisalConstants';
import { sendContactForm } from '@ja/helpers/sendContactForm';
import { FreeConsultationValidationSchema } from '../validationSchemas';
import { currentJewelryHintSelector } from '@ja/redux/selectors/user';

const mapStateToProps = (state) => {
  return {
    selectedPurpose: state.contact.purpose,
    selectedJewelry: state.contact.jewelry,
    purposeOptions: APPRAISAL_CONSTANTS.PURPOSES.map(({ title }) => ({
      value: title,
      label: title,
    })),
    jewelryOptions: APPRAISAL_CONSTANTS.JEWELRY.map(({ title }) => ({
      value: title,
      label: title,
    })),
    detailsPlaceholderHint: currentJewelryHintSelector(state),
  };
};

const FreeConsultationFormik = withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phone: '',
    mightHave: '',
    message: '',
  }),
  validationSchema: FreeConsultationValidationSchema,
  handleSubmit: (values, { resetForm, setSubmitting, setStatus }) => {
    setTimeout(() => {
      sendContactForm(
        values,
        resetForm,
        setStatus,
        setSubmitting,
        'Free Consultation'
      );
    }, 1000);
  },
  displayName: 'FreeConsultationForm',
})(FreeConsultationComponent);

const FreeConsultationForm = connect(
  mapStateToProps,
  null
)(FreeConsultationFormik);

export default FreeConsultationForm;
