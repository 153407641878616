import { MODAL_CLOSED, MODAL_OPENED } from '@ja/redux/actions/modal';

const initialState = {
  modalContent: {},
  modalType: '',
  isOpen: false,
};

export default function (state = initialState, { type, ...modalProps }) {
  switch (type) {
    case MODAL_OPENED:
      return {
        isOpen: true,
        ...modalProps,
      };
    case MODAL_CLOSED:
      return initialState;
    default:
      return state;
  }
}
