import {
  enableBodyScrolling,
  disableBodyScrolling,
} from '@ja/helpers/preventScrolling';

export const DRAWER_OPENED = 'DRAWER_OPENED';
export const DRAWER_CLOSED = 'DRAWER_CLOSED';

export const drawerOpened = (drawerType) => (dispatch, getState) =>
  dispatch({
    type: DRAWER_OPENED,
    drawerType,
    drawerProps: {
      open: true,
      selectedItems: getState().yourAppraisal.selectedItems,
    },
  });

export const drawerClosed = () => ({ type: DRAWER_CLOSED });

export const openDrawer = (payload) => (dispatch) => {
  disableBodyScrolling();
  return dispatch(drawerOpened(payload));
};

export const closeDrawer = (payload) => (dispatch) => {
  enableBodyScrolling();
  return dispatch(drawerClosed(payload));
};
