export const removeElFromArray = (arr, el) => {
  const array = arr;
  const index = array.indexOf(el);

  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
};

export const arrayMove = (arr, value, newIndex) => {
  let oldIndex = arr.findIndex((el) => el.value === value) || -1;

  while (oldIndex < 0) {
    return arr;
    // oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const setIfNotDuplicate = (arr, newObject) => {
  const newArray = [...arr, newObject];
  return [...new Set(newArray.map((obj) => obj))];
};
