import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { GetInTouchComponent } from './GetInTouchComponent';
import { APPRAISAL_CONSTANTS } from '@ja/pages/AppraisalServices/appraisalConstants';
import { sendContactForm } from '@ja/helpers/sendContactForm';
import { GetInTouchValidationSchema } from '../validationSchemas';
import { currentJewelryHintSelector } from '@ja/redux/selectors/user';

const mapStateToProps = (state) => {
  return {
    selectedPurpose: state.contact.purpose,
    selectedJewelry: state.contact.jewelry,
    purposeOptions: APPRAISAL_CONSTANTS.PURPOSES.map(({ title }) => ({
      value: title,
      label: title,
    })),
    jewelryOptions: APPRAISAL_CONSTANTS.JEWELRY.map(({ title }) => ({
      value: title,
      label: title,
    })),
    detailsPlaceholderHint: currentJewelryHintSelector(state),
  };
};

const ContactSlideoutFormik = withFormik({
  mapPropsToValues: ({ selectedJewelry, selectedPurpose }) => ({
    name: '',
    email: '',
    jewelry: selectedJewelry
      ? [{ value: selectedJewelry, label: selectedJewelry }]
      : [],
    purpose: selectedPurpose
      ? [{ value: selectedPurpose, label: selectedPurpose }]
      : [],
    phone: '',
    message: '',
  }),
  validationSchema: GetInTouchValidationSchema,
  handleSubmit: (values, { resetForm, setSubmitting, setStatus }) => {
    setTimeout(() => {
      sendContactForm(
        values,
        resetForm,
        setStatus,
        setSubmitting,
        'Appraisal Appointment'
      );
    }, 1000);
  },
  displayName: 'ContactSlideoutForm',
})(GetInTouchComponent);

const GetInTouchForm = connect(mapStateToProps, null)(ContactSlideoutFormik);

export default GetInTouchForm;
