import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { loadComponents, getState } from 'loadable-components';
import App from './App';
import history from './helpers/browserHistory';
import store from './redux/store';
import cssVars from 'css-vars-ponyfill';
import { disableReactDevToolsOnProd } from './helpers/disableReactDevToolsOnProd';
import initLogrocket from './helpers/logrocket';
import { unregister } from './registerServiceWorker';

import './styles/index.css';

disableReactDevToolsOnProd();
cssVars();
initLogrocket();

const rootElement = document.getElementById('root');
const AppWithProviderAndRouter = (
  <Provider store={store}>
    <Router history={history}>
      <Route render={() => <App />} />
    </Router>
  </Provider>
);

window.snapSaveState = () => getState();

loadComponents()
  .then(() => hydrate(AppWithProviderAndRouter, rootElement))
  .catch(() => render(AppWithProviderAndRouter, rootElement));

unregister();
