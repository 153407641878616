import { CURRENT_ITEM, CURRENT_TYPE } from '@ja/redux/actions/user';

const initialState = {
  currentJewelry: '',
  currentPurpose: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CURRENT_ITEM:
      return {
        ...state,
        currentJewelry: payload.item,
      };
    case CURRENT_TYPE:
      return {
        ...state,
        currentPurpose: payload.type,
      };
    default: {
      return state;
    }
  }
}
