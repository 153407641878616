import React from 'react';
import PropTypes from 'prop-types';
import { bemify } from '@ja/helpers/bemClassHelper';

import './Note.css';

const noteClasses = bemify('note');

const Note = ({ note, ...otherProps }) => (
  <>
    <div className={noteClasses()} {...otherProps}>
      {note}
    </div>
  </>
);

Note.propTypes = {
  note: PropTypes.string.isRequired,
};

export default Note;
