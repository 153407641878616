import * as React from 'react';

const useCallbackRef = () => {
  const [ready, setReady] = React.useState(false);
  const ref = React.useRef(null);
  const setRef = React.useCallback((node) => {
    ref.current = node;
    setReady(!!node);
  }, []);

  return [setRef, ref, ready];
};

const defaultOptions = {
  callback: () => undefined,
  customCallback: undefined,
};
const useOutsideClick = ({ callback, customCallback } = defaultOptions) => {
  const [setRef, ref, refReady] = useCallbackRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const clickedWithin = ref?.current?.contains?.(event.target);

      if (customCallback) return customCallback(event, clickedWithin, ref);

      if (!clickedWithin) {
        callback(event);
      }
    };

    if (refReady) {
      document.addEventListener('click', handleClick, true);
    }
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [refReady]);

  return setRef;
};

export { useOutsideClick };
