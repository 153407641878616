import React from 'react';
import PropTypes from 'prop-types';
import { bemify } from '@ja/helpers/bemClassHelper';

import './Spinner.css';

const spinnerClasses = bemify('spinner');

const Spinner = ({ inline, text }) => (
  <div className={spinnerClasses('container', inline ? 'inline' : '')}>
    <div className={spinnerClasses('loader')} />
    {text && <div className={spinnerClasses('text')}>{text}</div>}
  </div>
);

Spinner.defaultProps = {
  inline: false,
};

Spinner.propTypes = {
  inline: PropTypes.bool,
};

export default Spinner;
