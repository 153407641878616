import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import LogRocket from 'logrocket';

// reducers (sort by name)
import drawer from './reducers/drawer';
import modal from './reducers/modal';
import user from './reducers/user';
import yourAppraisal from './reducers/yourAppraisal';
import contact from './reducers/contact';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // actionsBlacklist: ['']
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk, LogRocket.reduxMiddleware())
);

const rootReducer = combineReducers({
  drawer,
  modal,
  user,
  yourAppraisal,
  contact,
});

const store = createStore(rootReducer, enhancer);

export default store;
