import { bemify } from '@ja/helpers/bemClassHelper';

const jewelryModalClasses = bemify('jewelry-modal');
const purposeModalClasses = bemify('purpose-modal');

export const MODAL_CLASSNAMES = {
  JEWELRY_ITEM: {
    bemClasses: jewelryModalClasses,
    overlayClassName: jewelryModalClasses('overlay'),
    className: jewelryModalClasses('content'),
  },
  PURPOSE_ITEM: {
    bemClasses: purposeModalClasses,
    overlayClassName: purposeModalClasses('overlay'),
    className: purposeModalClasses('content'),
  },
};

export const getModalClasses = (modalType) => MODAL_CLASSNAMES[modalType];
