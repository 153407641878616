import {
  JEWELRY_SELECTED,
  PURPOSE_SELECTED,
} from '@ja/redux/actions/yourAppraisal';
import { setIfNotDuplicate } from '@ja/helpers/arrayHelpers';

const initialState = {
  selectedJewelry: [],
  selectedPurpose: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case JEWELRY_SELECTED:
      return {
        ...state,
        selectedJewelry: setIfNotDuplicate(state.selectedJewelry, payload),
      };
    case PURPOSE_SELECTED:
      return {
        ...state,
        selectedPurpose: setIfNotDuplicate(state.selectedPurpose, payload),
      };
    default: {
      return state;
    }
  }
}
