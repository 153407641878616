import {
  enableBodyScrolling,
  disableBodyScrolling,
} from '@ja/helpers/preventScrolling';

export const MODAL_OPENED = 'MODAL_OPENED';
export const MODAL_CLOSED = 'MODAL_CLOSED';

export const modalOpened =
  ({ modalType, modalContent }) =>
  (dispatch) =>
    dispatch({
      type: MODAL_OPENED,
      modalType,
      modalContent,
    });

export const modalClosed = () => ({ type: MODAL_CLOSED });

export const openModal = (payload) => (dispatch) => {
  disableBodyScrolling();
  return dispatch(modalOpened(payload));
};

export const closeModal = (payload) => (dispatch) => {
  enableBodyScrolling();
  return dispatch(modalClosed(payload));
};
