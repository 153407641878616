import { createSelector } from 'reselect';
import { APPRAISAL_CONSTANTS } from '@ja/pages/AppraisalServices/appraisalConstants';

export const currentJewelryHintSelector = createSelector(
  [(state) => state.user.currentJewelry],
  (currentJewelry = undefined) => {
    const findByItem = currentJewelry || 'Diamond Appraisals';
    const jewelryItem = APPRAISAL_CONSTANTS.JEWELRY.find(
      (j) => findByItem === j.value
    );
    return jewelryItem.hint;
  }
);
