import * as Yup from 'yup';

export const GetInTouchValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export const FreeConsultationValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  mightHave: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
});
