import React from 'react';
import { handleHowWeWorkOnClick } from '../modalHelpers';

import './PurposeModalContent.css';

export const PurposeModalContent = ({
  content,
  bemClasses,
  CloseButton,
  handleCloseModal,
  handleCTA,
}) => {
  const {
    info: { language, report },
    name,
    title,
    value,
  } = content;

  const renderLanguage = () =>
    language.map(({ title = '', description = '' }) => (
      <div key={title} className={bemClasses('language-content-container')}>
        <h3 className={bemClasses('language-title')}>{title}</h3>
        {description && (
          <ul className={bemClasses('language-description-list')}>
            {description.map((li) => (
              <li key={li}>{li}</li>
            ))}
          </ul>
        )}
      </div>
    ));

  return (
    <div className={bemClasses()}>
      <div className={bemClasses('header')}>
        <div className={bemClasses('icon')}>
          <img src={content.icon} alt={value} />
        </div>
        <h2 className={bemClasses('title')}>{value}</h2>
        <CloseButton />
      </div>
      <div className={bemClasses('body', report ? '' : 'row')}>
        <div className={bemClasses('language', report ? 'first-of' : '')}>
          {renderLanguage()}
        </div>
      </div>
      <div className={bemClasses('footer')}>
        <button
          onClick={() => handleCTA({ purpose: title })}
          className={bemClasses('cta')}>
          Get your {name?.toLowerCase()} appraisal
        </button>
        <button
          className={bemClasses('cta', 'info')}
          onClick={handleHowWeWorkOnClick(handleCloseModal)}>
          Learn about how we work
        </button>
      </div>
    </div>
  );
};
