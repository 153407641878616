import { default as Sib } from 'sib-api-v3-sdk/src/index';

const defaultClient = Sib.ApiClient.instance;

const prepareSendInBlueApi = () => {
  const apiKey = defaultClient.authentications['api-key'];
  apiKey.apiKey = process.env.REACT_APP_SENDINBLUE_API_KEY;

  const apiInstance = new Sib.TransactionalEmailsApi();
  const sendSmtpEmail = new Sib.SendSmtpEmail();

  return {
    apiInstance,
    sendSmtpEmail,
  };
};

export { prepareSendInBlueApi };
