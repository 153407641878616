import './FreeConsultationComponent.css';

import React from 'react';
import NumberFormat from 'react-number-format';

import { bemify } from '@ja/helpers/bemClassHelper';
import { Button } from '@ja/components/common/Button/Button';
import { FormRow } from '../FormRow';
import { useState } from 'react';

const freeConsultationComponentClasses = bemify('free-consultation-form');

export const FreeConsultationComponent = ({
  handleBlur,
  detailsPlaceholderHint,
  handleChange,
  handleSubmit,
  isSubmitting,
  setStatus,
  values,
  errors,
  touched,
  status,
}) => {
  const [focusedField, setFocusedField] = useState('');

  const getFocusModifierClass = (name) =>
    focusedField === name ? 'has-focus' : '';

  const handleOnFocus = (e) => setFocusedField(e.target.name);

  const handleOnBlur = (e) => {
    if (e) handleBlur(e);
    setFocusedField('');
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={freeConsultationComponentClasses('form')}>
      <FormRow
        label="First name"
        bemClasses={freeConsultationComponentClasses}
        modifierClass={getFocusModifierClass('name')}
        renderInput={() => (
          <>
            <input
              className={freeConsultationComponentClasses('input', [
                'name',
                errors.name && touched.name ? 'error' : '',
              ])}
              type="text"
              name="name"
              placeholder="Jane"
              value={values.name}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              onChange={handleChange}
            />
            {errors.name && touched.name && (
              <div id="feedback">{errors.name}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Email"
        bemClasses={freeConsultationComponentClasses}
        modifierClass={getFocusModifierClass('email')}
        renderInput={() => (
          <>
            <input
              className={freeConsultationComponentClasses('input', [
                'email',
                errors.email && touched.email ? 'error' : '',
              ])}
              type="email"
              name="email"
              placeholder="jane.smith@gmail.com"
              value={values.email}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email && (
              <div id="feedback">{errors.email}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Phone number"
        bemClasses={freeConsultationComponentClasses}
        modifierClass={getFocusModifierClass('phone')}
        renderInput={() => (
          <>
            <NumberFormat
              className={freeConsultationComponentClasses('input', [
                'phone',
                errors.phone && touched.phone ? 'error' : '',
              ])}
              name="phone"
              format="### . ### . ####"
              placeholder="--- . --- . ----"
              mask="-"
              value={values.phone}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.phone && touched.phone && (
              <div id="feedback">{errors.phone}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="You might have"
        note="Describe what you have, so we have an idea of how we can help."
        bemClasses={freeConsultationComponentClasses}
        modifierClass={getFocusModifierClass('mightHave')}
        renderInput={() => (
          <>
            <textarea
              style={{ resize: 'none' }}
              className={freeConsultationComponentClasses('input', [
                'mightHave',
                errors.mightHave && touched.mightHave ? 'error' : '',
              ])}
              name="mightHave"
              rows={2}
              placeholder={detailsPlaceholderHint}
              value={values.mightHave}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.mightHave && touched.mightHave && (
              <div id="feedback">{errors.mightHave}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Other details"
        note="Share any questions or concerns you have..."
        bemClasses={freeConsultationComponentClasses}
        modifierClass={getFocusModifierClass('message')}
        renderInput={() => (
          <>
            <textarea
              style={{ resize: 'none' }}
              className={freeConsultationComponentClasses('input', [
                'message',
                errors.message && touched.message ? 'error' : '',
              ])}
              name="message"
              rows={3}
              placeholder="..."
              value={values.message}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.message && touched.message && (
              <div id="feedback">{errors.message}</div>
            )}
          </>
        )}
      />

      <div
        className={freeConsultationComponentClasses(
          'form-row',
          status
            ? [
                status.includes('Uh-oh') ? 'button-failed' : 'button-success',
                'button',
              ]
            : 'button'
        )}>
        <Button
          processing={isSubmitting}
          processingText="Sending..."
          successText="Message Sent. We will reach out ASAP."
          type="submit"
          text={status || 'Send inquiry'}
          disabled={isSubmitting || status}
        />
        {status && status.includes('Uh-oh') && (
          <div className={freeConsultationComponentClasses('retry-container')}>
            <div
              onClick={() => {
                handleSubmit();
                setStatus('');
              }}
              role="presentation"
              className={freeConsultationComponentClasses('retry-button')}>
              Yes, Resend Message
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
