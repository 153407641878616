import ScrollMagic from '@ja/helpers/ScrollMagic';

// If the last event is changed in the timeline, this constant needs to be updated
const lastEventId = '#event_eight';

export const initializeTimeLine = (mobile = false) => {
  const controller = new ScrollMagic.Controller();

  const intializePebbles = () => {
    const pebbleArray = document.querySelectorAll('.time-line__pebble');
    let translateNum = 0;

    pebbleArray.forEach((pebble, idx) => {
      if (mobile && idx > 91) return;
      if (!mobile && idx > 87) return;

      const pebbleID = '#' + pebble.id || '';
      const pebbleNum = pebbleIDToNum(pebble.id);
      const isLastPebbleScene = pebble.classList.value.includes(
        'time-line__pebble--last-pebble-scene'
      );

      new ScrollMagic.Scene({
        triggerElement: isLastPebbleScene ? lastEventId : pebbleID,
      })
        .setClassToggle(pebbleID, 'animated')
        .setTween(pebbleID, 0.5, {
          scale: 1,
          borderColor: 'rgb(225, 225, 225)',
          opacity: 1,
        })
        .setTween(pebbleID, 0.5, {
          borderColor: 'rgb(225, 225, 225)',
          backgroundColor: 'rgba(60, 62, 232, .9)',
          transform: mobile ? 0 : `translateX(${translateNum}rem)`,
        })
        .addTo(controller);

      switch (pebbleNum) {
        case 3:
          return (translateNum -= 0.5);
        case 4:
          return (translateNum -= 1.25);
        case 5:
          return (translateNum -= 2.5);
        case 6:
          return (translateNum -= 2.5);
        case 7:
          return (translateNum -= 2.5);
        case 8:
          return (translateNum -= 2.5);
        case 9:
          return (translateNum -= 2.5);
        case 10:
          return (translateNum -= 2.5);
        case 11:
          return (translateNum -= 2.5);
        case 12:
          return (translateNum -= 2);
        case 13:
          return (translateNum -= 1.25);
        default:
          break;
      }
    });
  };

  const initializeEvents = () => {
    const eventArray = document.querySelectorAll('.time-line__event');
    const lastId = eventArray[eventArray.length - 1].id || {};

    eventArray.forEach((event, idx) => {
      if (idx > 8) return;

      const eventID = '#' + event.id || '';
      const isLastEventScene = event.id === lastId;

      new ScrollMagic.Scene({
        triggerElement: isLastEventScene ? lastEventId : eventID,
      })
        .setClassToggle(eventID, 'animated')
        .setTween(eventID, 0.5, {
          scale: 1,
          opacity: 1,
          immediateRender: idx > 0 ? false : undefined,
        })
        .addTo(controller);
    });
  };

  intializePebbles();
  initializeEvents();
  return controller;
};

function pebbleIDToNum(id) {
  return Number(id.replace('pebble', ''), 10);
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getTodaysDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  const todaysDate = `${month} ${day}, ${year}`;
  return todaysDate || '';
};

export const getDavidsYearsOfExperience = () => {
  const firstYear = 1976;
  const currentYear = new Date().getFullYear();

  const yearsOfExperience = `${(currentYear - firstYear).toString()}+`;

  return yearsOfExperience || '42+';
};

export const getCompanyYears = () => {
  const firstYear = 1998;
  const currentYear = new Date().getFullYear();

  const companyYears = (currentYear - firstYear).toString();

  return companyYears || '20';
};
