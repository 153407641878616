/* ====================================================
              SELECT JEWELRY ACTIONS 
==================================================== */
export const JEWELRY_SELECTED = 'JEWELRY_SELECTED';

export const setSelectedJewelry = (payload) => ({
  type: JEWELRY_SELECTED,
  payload,
});

/* ====================================================
              SELECT PURPOSE ACTIONS 
==================================================== */
export const PURPOSE_SELECTED = 'PURPOSE_SELECTED';

export const setSelectedPurpose = (payload) => ({
  type: PURPOSE_SELECTED,
  payload,
});
