import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import { bemify } from '@ja/helpers/bemClassHelper';
import history from '@ja/helpers/browserHistory';

import './Button.css';

export const Button = (props) => {
  const {
    text,
    processingText,
    successText,
    customClass,
    redirect,
    onClick,
    processing,
    ...otherProps
  } = props;
  const jaButtonClasses = bemify('ja-button');
  let handleOnClick = () => {};
  let passProps = {
    ...otherProps,
  };

  if (onClick) {
    handleOnClick = (e) => {
      e.preventDefault();
      if (redirect) {
        return history.push(redirect);
      }
      onClick();
    };
    passProps = {
      onClick: handleOnClick,
      ...otherProps,
    };
  }

  return (
    <div className={jaButtonClasses('wrapper', customClass)}>
      <button
        className={jaButtonClasses('button', customClass)}
        onClick={handleOnClick}
        disabled={processing}
        {...passProps}>
        <span className={jaButtonClasses('call-text', customClass)}>
          {processing ? <Spinner text={processingText} inline /> : text}
        </span>
      </button>
    </div>
  );
};

Button.defaultProps = {
  customClass: '',
  onClick: false,
  redirect: '',
  text: '',
  processingText: '',
  successText: '',
};

Button.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  redirect: PropTypes.string,
  text: PropTypes.string,
  processingText: PropTypes.string,
  successText: PropTypes.string,
};
