import React from 'react';
import PropTypes from 'prop-types';
import Note from '@ja/components/common/Note';

export const FormRow = (props) => {
  const { bemClasses, label, note, renderInput, modifierClass } = props;
  return (
    <div className={bemClasses('form-row')}>
      <label className={bemClasses('label', modifierClass)}>
        {label}
        <Note note={note} />
      </label>
      {renderInput()}
    </div>
  );
};

FormRow.defaultProps = {
  modifierClass: '',
  note: '',
};

FormRow.propTypes = {
  bemClasses: PropTypes.func.isRequired,
  modifierClass: PropTypes.string,
  note: PropTypes.string,
  label: PropTypes.string.isRequired,
  renderInput: PropTypes.func.isRequired,
};
