import { UIContextProvider } from './UIContext';
import {
  ThemeProvider,
  makeTheme,
  overridePalette,
} from '@real-system/styled-library';

const palette = overridePalette('realSystem', {
  purple: 'rgba(60, 62, 232, 0.9)',
});

const theme = makeTheme(palette);

const JustAppraisersProviders = ({ children }) => {
  return (
    <ThemeProvider preventGlobals theme={theme}>
      <UIContextProvider>{children}</UIContextProvider>
    </ThemeProvider>
  );
};

export { JustAppraisersProviders };
