import React from 'react';
import GetInTouchForm from './GetInTouch/GetInTouchContainer';
import FreeConsultationForm from './FreeConsultation/FreeConsultationContainer';

const TYPES = {
  GetInTouch: GetInTouchForm,
  FreeConsultation: FreeConsultationForm,
};

export const InnerSlideout = ({ type = '' }) => {
  const Component = TYPES[type];
  if (!Component) return null;
  return <Component />;
};
