import './GetInTouchComponent.css';

import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import { bemify } from '@ja/helpers/bemClassHelper';
import { Button } from '@ja/components/common/Button/Button';
import Select from '@ja/components/common/Select/Select';
import { FormRow } from '../FormRow';

const getInTouchComponentClasses = bemify('get-in-touch-form');

export const GetInTouchComponent = ({
  detailsPlaceholderHint,
  jewelryOptions,
  purposeOptions,
  setFieldValue,
  handleChange,
  handleSubmit,
  isSubmitting,
  setStatus,
  handleBlur,
  values,
  errors,
  touched,
  status,
}) => {
  const [focusedField, setFocusedField] = useState('');

  const getFocusModifierClass = (name) =>
    focusedField === name ? 'has-focus' : '';

  const handleOnFocus = (e) => setFocusedField(e.target.name);

  const handleOnBlur = (e) => {
    if (e) handleBlur(e);
    setFocusedField('');
  };

  const handleSelectOnFocus = (updatedHasFocus) =>
    setFocusedField(updatedHasFocus);

  return (
    <form
      onSubmit={handleSubmit}
      className={getInTouchComponentClasses('form')}>
      <FormRow
        label="First name"
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('name')}
        renderInput={() => (
          <>
            <input
              className={getInTouchComponentClasses('input', [
                'name',
                errors.name && touched.name ? 'error' : '',
              ])}
              type="text"
              name="name"
              placeholder="Jane"
              value={values.name}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              onChange={handleChange}
            />
            {errors.name && touched.name && (
              <div id="feedback">{errors.name}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Email"
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('email')}
        renderInput={() => (
          <>
            <input
              className={getInTouchComponentClasses('input', [
                'email',
                errors.email && touched.email ? 'error' : '',
              ])}
              type="email"
              name="email"
              placeholder="janedoe@gmail.com"
              value={values.email}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email && (
              <div id="feedback">{errors.email}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Phone number"
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('phone')}
        renderInput={() => (
          <>
            <NumberFormat
              className={getInTouchComponentClasses('input', [
                'phone',
                errors.phone && touched.phone ? 'error' : '',
              ])}
              name="phone"
              format="### . ### . ####"
              placeholder="212 315 0750"
              mask="-"
              value={values.phone}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleChange}
            />
            {errors.phone && touched.phone && (
              <div id="feedback">{errors.phone}</div>
            )}
          </>
        )}
      />

      <FormRow
        label="Your Jewelry"
        note="If your jewelry isn't in the list, you can add it by typing it in and pressing enter."
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('jewelry')}
        renderInput={() => (
          <Select
            closeMenuOnSelect={false}
            options={jewelryOptions}
            placeholder="Diamond, Watch, etc."
            formatCreateLabel={(item) => `Add "${item}" to the list`}
            customClass={getInTouchComponentClasses('input', 'select')}
            name="jewelry"
            value={values.jewelry}
            onFocus={handleSelectOnFocus}
            onBlur={handleOnBlur}
            onChange={(value) => setFieldValue('jewelry', value)}
            classNamePrefix="select-jewelry"
          />
        )}
      />

      <FormRow
        label="Your appraisal purpose"
        note="If your appraisal purpose isn't in the list, you can add it by typing it in and pressing enter."
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('purpose')}
        renderInput={() => (
          <Select
            closeMenuOnSelect={false}
            options={purposeOptions}
            placeholder="Insurance, Liquidation, etc."
            formatCreateLabel={(item) => `Add "${item}" to the list`}
            customClass={getInTouchComponentClasses('input', 'select')}
            name="purpose"
            value={values.purpose}
            onFocus={handleSelectOnFocus}
            onBlur={handleOnBlur}
            onChange={(value) => setFieldValue('purpose', value)}
            classNamePrefix="select-purpose"
          />
        )}
      />

      <FormRow
        label="Other details"
        bemClasses={getInTouchComponentClasses}
        modifierClass={getFocusModifierClass('message')}
        renderInput={() => (
          <textarea
            style={{ resize: 'none' }}
            className={getInTouchComponentClasses('input', 'message')}
            name="message"
            rows={3}
            placeholder={detailsPlaceholderHint}
            value={values.message}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleChange}
          />
        )}
      />

      <div
        className={getInTouchComponentClasses(
          'form-row',
          status
            ? [
                status.includes('Uh-oh') ? 'button-failed' : 'button-success',
                'button',
              ]
            : 'button'
        )}>
        <Button
          processing={isSubmitting}
          processingText="Sending..."
          type="submit"
          text={status || 'Send inquiry'}
          disabled={isSubmitting || status}
        />
        {status && status.includes('Uh-oh') && (
          <div className={getInTouchComponentClasses('retry-container')}>
            <div
              onClick={() => {
                handleSubmit();
                setStatus('');
              }}
              role="presentation"
              className={getInTouchComponentClasses('retry-button')}>
              Yes, Resend Message
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
